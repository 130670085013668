<template>
	<div v-if="adInfor">
		<div class="person_infor">
			<div class="login_top">
				<div class="img"> <img src="@/assets/login/flag.png"></div>
				<div class="login_title" @click="backClick">{{$t('myAd')}}</div>
				<div class="front_img">
					<img src="@/assets/home_icons/front.png">
				</div>
				<div class="sub_title">{{$t('title.advertiseDetail')}}</div>
			</div>
			<div class="tip blue" v-if="adInfor.check_status==1">
				<!-- 1待审核2审核通过3审核拒绝4到期 -->
				{{$t('curState')}}：{{$t('my.checkIn')}}
			</div>
			<div class="tip green" v-if="adInfor.check_status==2">
				<!-- 1待审核2审核通过3审核拒绝4到期 -->
				{{$t('curState')}}：{{$t('my.applySuccess')}}
			</div>
			<div class="tip red" v-if="adInfor.check_status==3">
				<!-- 1待审核2审核通过3审核拒绝4到期 -->
				<div>{{$t('curState')}}：{{$t('my.applyFailure')}}</div>
				<div class="reason">{{adInfor.remark}}</div>
			</div>
			<div class="all_list">
				<div class="list_item">
					<div class="list_item_top">
						{{$t('order.adPosition')}}
					</div>
					<div class="list_item_bottom">
						{{adInfor.order_info.ads_type_name}}
					</div>
				</div>
				<div class="list_item">
					<div class="list_item_top">
						{{$t('order.adContent')}}
					</div>
					<div class="list_item_bottom">
						<!-- <div class="list_item_bottom_left">
							<img src="../../../../assets/bottom_icons/1.png">
						</div> -->
						<div class="list_item_bottom_right">
							<div class="ad_content">
								{{adInfor.information_name}}
							</div>
						</div>
					</div>
				</div>
				<div class="list_item">
					<div class="list_item_top">
						{{$t('order.adDuration')}}
					</div>
					<div class="list_item_bottom">
						{{adInfor.order_info.num}}
					</div>
				</div>
				<div class="list_item" style="border-bottom: none;">
					<div class="list_item_top">
						{{$t('order.photo')}}({{$t('order.aSheet')}})
					</div>
					<div class="list_item_bottom">
						<div class="all_imgs">
							<img :src="adInfor.thumb_url">
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				id: '',
				adInfor: "",
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.getAdDetail();
		},
		methods: {
			getAdDetail() {
				this.$http.myAdsInfo({
					id: this.id,
				}).then(res => {
					if (res.code == 1) {
						this.adInfor = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			backClick() {
				this.$router.back();
			},

		}
	}
</script>

<style scoped="" lang="less">
	.tip {
		box-sizing: border-box;
		margin: 32px 0 0 50px;
		width: 830px;

		padding: 16px 24px;
		font-size: 16px;
		padding-left: 40px;
		text-align: left;
	}

	.blue {
		background: rgba(65, 119, 221, 0.09);
		color: #4177DD;
	}

	.green {
		background: rgba(117, 190, 128, 0.09);
		color: #75BE80;
	}

	.red {
		color: #E60012;
		background: rgba(230, 0, 18, 0.09);
	}

	.reason {
		margin-top: 12px;
		color: #333333;
		font-size: 12px;
	}


	.person_infor {
		width: 926px;
	}


	.login_top {
		display: flex;
		align-items: center;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;


		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;

			&:hover {
				cursor: pointer;
			}
		}

		.front_img {
			display: flex;
			align-items: center;
			margin: 0 12px;

			img {
				width: 8px;
				height: 14px;
			}
		}
	}

	.all_list {
		margin: 0 50px;
		padding-top: 24px;
	}

	.list_item {
		text-align: left;
		padding: 24px 0;
		border-bottom: 1px solid #F3F5F6;
		font-size: 16px;

		&:last-child {
			border-bottom: none;
		}

		.list_item_top {
			span {
				color: #E60012;
			}
		}

		.list_item_bottom {
			display: flex;
			margin-top: 28px;
			color: #4177DD;
			font-size: 14px;

			/deep/ .el-input__inner {
				width: 200px;
				border: none !important;
			}

			.list_item_bottom_left {
				img {
					width: 64px;
					height: 64px;
				}
			}

			.list_item_bottom_right {
				width: 600px;
				// margin-left: 16px;

				color: #333333;
			}
		}
	}

	.btn {
		margin-top: 74px;
		text-align: left;

		div {
			text-align: center;
			display: inline-block;
			min-width: 280px;
			padding: 14px 0;
			background: #E60012;
			opacity: 1;
			border-radius: 24px;
			font-size: 16px;
			color: #FFFFFF;
		}

	}

	.all_imgs {
		img {
			width: 180px;
			height: 180px;
		}
	}
</style>
